import { Button, Carousel, Checkbox, Form, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { loginUser, userSelector } from "../store/reducers/user.reducer";
import CardBox from "./CardBox";
import { InputBox, InputPasswordBox } from "./InputBox";
// import { useEffect } from "react";

const Login = () => {
  const [form] = Form.useForm();
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(userSelector);
  // const { loggedIn } = useAppSelector(userSelector);

  // useEffect(() => {
  //   if (!loggedIn && window.location.pathname.split("/").length > 1) {
  //     nav("/");
  //   }
  // }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    dispatch(loginUser(values))
      .then(() => {
        // if (res.payload.data.statusCode == 200) {
        nav("/");
        // }
      })
      .catch((err) => {
        alert(err);
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-console
  const onFinishFailed = (errorInfo: any) => console.log("Failed:", errorInfo);

  return (
    <>
      <CardBox className="loginBox mt-4 d-none">
        <h1 className="text-center text-primary mb-4 pb-4">
          <Image
            preview={false}
            width={200}
            src={`${location.protocol + "//" + location.host}/logo.svg`}
          />
        </h1>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <InputBox
            name={"email"}
            label="Username"
            type="text"
            rules={[{ required: true, message: "Please input your username!" }]}
          />
          <InputPasswordBox
            name={"password"}
            label="Password"
            type="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          />

          <Form.Item className="mb-3" name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item className="mb-0">
            <Button
              type="primary"
              className="w-100"
              size="large"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </CardBox>
      <div className="myLoginBox row m-0 h-100">
        <div className={" p-0 col-sm-8 m-auto"}>
          <div className="login-page row m-0">
            <div className="col-sm-6 p-0 position-relative">
              <div className="p-3 position-absolute z-2">
                <img src="./logo.svg" width={100} alt="logo" />
              </div>
              <div className="customCarouselCss">
                <Carousel autoplay dots={false}>
                  <div className="img-Div-Carousel">
                    <img
                      className="img-fluid"
                      src="../../one.jpg"
                      alt="Login"
                    />
                  </div>
                  <div className="img-Div-Carousel">
                    <img
                      className="img-fluid"
                      src="../../two.jpg"
                      alt="Login"
                    />
                  </div>
                  <div className="img-Div-Carousel">
                    <img
                      className="img-fluid"
                      src="../../three.jpg"
                      alt="Login"
                    />
                  </div>
                  <div className="img-Div-Carousel">
                    <img
                      className="img-fluid"
                      src="../../four.jpg"
                      alt="Login"
                    />
                  </div>
                  <div className="img-Div-Carousel">
                    <img
                      className="img-fluid"
                      src="../../five.jpg"
                      alt="Login"
                    />
                  </div>
                </Carousel>
                {/* <div className="login-footer-div">
                  <h6>
                    An <span>Integrated</span> Call Center Solution
                  </h6>
                  <p>
                    An call center solution that automates all business
                    communication and brings an unsurpassed experience to your
                    customers.
                  </p>
                </div> */}
              </div>
            </div>
            <div className="col-sm-6 bg-white d-flex justify-content-center align-items-center rounded-end-12">
              <Form
                id="login-form"
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <p className="form-title">
                  Welcome to{" "}
                  <span style={{ color: "#2E54D4" }}>
                    Realtime Data Stock Market
                  </span>
                </p>
                <p className="form-Sub-title">Login</p>
                <InputBox
                  name={"email"}
                  label="Username"
                  type="text"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                />
                <InputPasswordBox
                  name={"password"}
                  label="Password"
                  type="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                />
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Form.Item
                    className="mb-0"
                    name="remember"
                    valuePropName="checked"
                  >
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                  <a href={"/register"}>Register</a>
                </div>

                <Form.Item className="mb-0">
                  <Button
                    type="primary"
                    className="w-100"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
