import { ICustomerCredentials, IRegisterForm } from "@/@types/common";
import API from "@/plugins/api";
/**
 * Adds two numbers together.
 */
class AuthService {
  /**
   * Login Method.
   * @param {object} payload Payload for API Body
   * @return {ICustomerCredentials} The sum of the two numbers.
   */
  login(payload: ICustomerCredentials) {
    return API.post(`/auth/login`, payload);
    // if (payload.email == "admin@gmail.com" && payload.password == "admin") {
    //   return {
    //     data: {
    //       data: {
    //         email: "admin@gmail.com",
    //         roleId: 1,
    //         name: "Admin",
    //         agentId: "admin",
    //         role: "Admin",
    //       },
    //       status: true,
    //       message: "User login successfully",
    //       statusCode: 200,
    //     },
    //     status: true,
    //   };
    // } else if (
    //   payload.email == "agent@gmail.com" &&
    //   payload.password == "agent@123"
    // ) {
    //   return {
    //     data: {
    //       data: {
    //         email: "agent@gmail.com",
    //         roleId: 2,
    //         name: "Agent",
    //         agentUId: "dd3dc72e-2232-40e8-bcae-eef8cffd3a93",
    //         agentId: "agent",
    //         role: "Agent",
    //       },
    //       status: true,
    //       message: "User login successfully",
    //       statusCode: 200,
    //     },
    //     status: true,
    //   };
    // } else if (
    //   payload.email == "supervisor@gmail.com" &&
    //   payload.password == "supervisor@123"
    // ) {
    //   return {
    //     data: {
    //       data: {
    //         email: "supervisor@gmail.com",
    //         roleId: 3,
    //         name: "Supervisor",
    //         // agentUId: "dd3dc72e-2232-40e8-bcae-eef8cffd3a93",
    //         agentId: "supervisor",
    //         role: "Supervisor",
    //       },
    //       status: true,
    //       message: "User login successfully",
    //       statusCode: 200,
    //     },
    //     status: true,
    //   };
    // } else {
    //   return {
    //     data: {
    //       status: true,
    //       message: "Please fill both the username and password fields!",
    //       statusCode: 400,
    //     },
    //     status: true,
    //   };
    // }
  }

  // eslint-disable-next-line require-jsdoc
  whoami() {
    return API.get(`/auth/whoami`);
  }
  // eslint-disable-next-line require-jsdoc
  logout() {
    return API.get(`/auth/logout`);
  }

  // eslint-disable-next-line require-jsdoc
  create(payload: IRegisterForm) {
    return API.post(`/users`, payload);
  }

  // eslint-disable-next-line require-jsdoc
  profile() {
    return API.get(`/users/profile`);
  }

  // eslint-disable-next-line require-jsdoc
  getAllApis() {
    return API.get(`/users/apis`);
  }

  // eslint-disable-next-line require-jsdoc
  createApiKey() {
    return API.post(`/users/api`);
  }

  // eslint-disable-next-line require-jsdoc
  update(payload: any) {
    return API.put(`/users/update`, payload);
  }

  // eslint-disable-next-line require-jsdoc
  all(offset?: number, limit?: number, searchStr?: string) {
    return API.get(
      `/users/all?limit=${limit}&offset=${offset}&search=${searchStr}`
    );
  }

  // eslint-disable-next-line require-jsdoc
  getUserById(Uuid: string) {
    return API.get(`/users/${Uuid}`);
  }

  // eslint-disable-next-line require-jsdoc
  getDeleteUserById(Uuid: string) {
    return API.delete(`/users/${Uuid}`);
  }
}
export default new AuthService();
