import API from "@/plugins/api";
/**
 * Adds two numbers together.
 */
class ProductsService {
  // eslint-disable-next-line require-jsdoc
  all(exchangeName: string) {
    return API.get(`/products?exchangeName=${exchangeName}`);
  }

}
export default new ProductsService();
