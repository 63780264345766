import Home from "@/pages";
import SettingsPage from "@/pages/Settings";
import {
  FileIcon,
  // MultiUsersIcon,
} from "@/components/Icons/CardBox";
import Icon, {
  SettingOutlined,
  UserOutlined,
  StockOutlined,
} from "@ant-design/icons";
import { Avatar, Image } from "antd";
// import { BiSolidDashboard } from "react-icons/bi";
// import { PiUsersThreeBold } from "react-icons/pi";
import Register from "@/components/Register";
import UserProfilePage from "@/pages/profile";
import ApiListPage from "@/pages/api";
import { GoPasskeyFill } from "react-icons/go";
import SubscriptionPage from "@/pages/subscription";
import { BiSolidDashboard } from "react-icons/bi";
import MarketWatchListPage from "@/pages/marketWatch";
import { IoMdPlanet } from "react-icons/io";
import PlansListPage from "@/pages/plan";

const routesData = [
  {
    id: 1,
    key: "/",
    component: <Home title={"Home"} />,
    icon: <BiSolidDashboard style={{ fontSize: "20px" }} />,
    exact: "true",
    label: "Dashboard",
    breadcrumbname: "Dashboard",
    allowedroles: ["admin", "superadmin", "User"],
  },
  {
    id: 2,
    key: "/admin/marketWatch",
    component: <MarketWatchListPage title={"Market Watch"} />,
    icon: <StockOutlined style={{ fontSize: "23px" }} />,
    label: "Market Watch",
    breadcrumbname: "Market Watch",
    hidden: false,
    allowedroles: ["admin", "superadmin", "User"],
  },
  {
    id: 3,
    key: "/admin/api",
    component: <ApiListPage title={"Api"} />,
    icon: <GoPasskeyFill style={{ fontSize: "23px" }} />,
    label: "API",
    breadcrumbname: "api",
    hidden: false,
    allowedroles: ["superadmin"],
  },
  {
    id: 4,
    key: "/admin/subscription",
    component: <SubscriptionPage title={"Subscription"} />,
    icon: <UserOutlined />,
    label: "Subscription",
    breadcrumbname: "Subscription",
    hidden: false,
    allowedroles: ["admin", "superadmin", "User"],
  },
  {
    id: 4,
    key: "/admin/plan",
    component: <PlansListPage title={"Plans"} />,
    icon: <IoMdPlanet style={{ fontSize: "23px" }} />,
    label: "Plans",
    breadcrumbname: "Plans",
    hidden: false,
    allowedroles: ["admin", "superadmin", "User"],
  },
  // {
  //   id: 4,
  //   key: "/admin/user/add",
  //   component: <AddUserPage title={"Add"} />,
  //   icon: <UserOutlined />,
  //   label: "Add",
  //   breadcrumbname: "add",
  //   hidden: true,
  //   allowedroles: ["admin", "superadmin"],
  // },
  // {
  //   id: 5,
  //   key: "/admin/user/:id/edit",
  //   component: <AddUserPage title={"Edit"} />,
  //   icon: <UserOutlined />,
  //   label: "Edit",
  //   breadcrumbname: "edit",
  //   hidden: true,
  //   allowedroles: ["admin", "superadmin"],
  // },
  {
    id: 6,
    key: "/admin/settings",
    component: <SettingsPage title={"Settings"} />,
    icon: <SettingOutlined />,
    label: "Settings",
    breadcrumbname: "Settings",
    hidden: true,
    allowedroles: ["admin", "superadmin"],
  },
  {
    id: 7,
    key: "/register",
    component: <Register />,
    icon: <SettingOutlined />,
    label: "Register",
    hidden: true,
  },
  {
    id: 8,
    key: "/admin/profile",
    component: <UserProfilePage title={"Profile"} />,
    icon: <UserOutlined />,
    label: "Profile",
    breadcrumbname: "Profile",
    hidden: true,
    allowedroles: ["admin", "superadmin"],
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AgentFooterLink: any = [
  {
    label: "Reports",
    icon: (
      <Avatar
        className="MyAvatar"
        size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 39, xxl: 100 }}
        icon={
          <Image
            className="rounded-circle h-100"
            preview={false}
            src="./img-4.jpg"
          />
        }
      />
    ),
    children: [
      {
        // key: "/agents/ptp",
        // component: <AgentPTPPage title={"PTP"} />,
        icon: <Icon component={() => <FileIcon width={30} height={30} />} />,
        label: "Logout",
      },
    ],
  },
  {
    // key: "/agents/ptp",
    // component: <AgentPTPPage title={"PTP"} />,
    icon: <Icon component={() => <FileIcon width={30} height={30} />} />,
    label: "Logout",
    hidden: true,
  },
];

const columns: (
  | {
      title: string;
      dataIndex: string;
      width: number;
    }
  | {
      title: string;
      dataIndex: string;
      width?: undefined;
    }
)[] = [
  {
    title: "Name",
    dataIndex: "name",
    width: 150,
  },
  {
    title: "Age",
    dataIndex: "age",
    width: 150,
  },
  {
    title: "Address",
    dataIndex: "address",
  },
];

const CampaignStatus = {
  idle: "idle",
  active: "active",
  pausing: "pausing",
  paused: "paused",
  down: "down",
  inactive: "inactive",
};
const CallStatus = {
  idle: "idle",
  active: "active",
  pausing: "pausing",
  paused: "paused",
  down: "down",
  inactive: "inactive",
};

const CampaignTypeOption = [
  {
    value: "predictive",
    label: "Predictive",
  },
  {
    value: "progressive",
    label: "Progressive",
  },
];

const CronStatusOption = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const pageSizeOptions: string[] = [
  "5",
  "10",
  "20",
  "30",
  "50",
  "100",
  "200",
  "250",
  "500",
  "1000",
];

const numbersToFormat = (number: number) => {
  if (Number(number) >= 999 && Number(number) <= 1000000) {
    return (Number(number) / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (Number(number) > 1000000) {
    return (Number(number) / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (Number(number) < 999) {
    return Number(number); // if value < 1000, nothing to do
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const exportCsvFileDownload = (response: any, fileName: string) => {
  const data = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = data;
  link.setAttribute("download", `${fileName}_${Date.now()}.csv`);
  document.body.appendChild(link);
  link.click();
  return response.data;
};

const extractEnclosedStrings = (inputString: string) => {
  // Regular expression to match and capture strings within {{}}
  const regex = /{{(.*?)}}/g;
  // Use the regex to find and capture the enclosed strings
  const matches = [];
  let match;
  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }
  return matches;
};

export {
  AgentFooterLink,
  CallStatus,
  CampaignStatus,
  CampaignTypeOption,
  CronStatusOption,
  columns,
  pageSizeOptions,
  exportCsvFileDownload,
  extractEnclosedStrings,
  numbersToFormat,
  routesData,
};
