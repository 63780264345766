import { Breadcrumb } from "antd";
// import { matchRoutes } from "react-router";
// import { LeftMenu } from "@/common";

// import { matchRoutes } from 'react-router-config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BreadcrumbBox = (props: any) => {
  return (
    <Breadcrumb className={props.className}>{props.breadcrumbItems}</Breadcrumb>
  );
};
export default BreadcrumbBox;
