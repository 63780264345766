import { PrimaryButtons } from "@/components/Button";
import { InputBox } from "@/components/InputBox";
import PageHeading from "@/components/PageHeading";
import { Col, Form, Row, Spin } from "antd";
import { Fragment, useState, useEffect } from "react";

const SettingsPage = (props: any) => {
  const [form] = Form.useForm();
  const [webHookOutPut, setWebHookOutPut] = useState<any | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = `${props?.title}`;
  }, []);

  const onFinish = () => {
    setLoading(true);
  };

  const onFinishFailed = () => setWebHookOutPut(undefined);

  return (
    <Fragment>
      <PageHeading title="Setting" />
      <Spin spinning={loading} delay={500} tip="Loading..." size="large">
        <Row
          className="m-0"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row ps-0" span={12}>
            <Form
              form={form}
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              className=""
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <InputBox
                tabIndex={1}
                name={"webhookUrl"}
                label="Webhook URL"
                type="url"
                // defaultValue={campaignById && campaignById.name}
                placeholder={"Please enter webhook URL"}
                errorMessage={"Please enter webhook URL"}
                required={true}
                rules={undefined}
              />
              <Form.Item className="m-auto d-flex justify-content-start align-items-center">
                <PrimaryButtons
                  tabIndex={2}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  name="Save"
                  loading={loading}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col className="gutter-row h-full mh-60 border rounded p-0" span={12}>
            <div>
              <h4 className="text-center border-bottom pb-2 pt-1 mb-3 bg-light">
                Output
              </h4>
              {webHookOutPut && (
                <pre className="px-4">
                  &#123;
                  <br />
                  <small>
                    &nbsp; &#34;webhookUrl: &#34; &#58; &#34;
                    {webHookOutPut && webHookOutPut?.data?.webhookUrl}
                    &#34;&#44;
                    <br />
                    &nbsp; &#34;status: &#34; &#58; &#34;
                    {`${webHookOutPut && webHookOutPut?.data?.status}`}
                    &#34;&#44;
                    <br />
                    &nbsp; &#34;message: &#34; &#58; &#34;
                    {`${webHookOutPut && webHookOutPut?.data?.message}`}
                    &#34;&#44;
                  </small>
                  <br />
                  &#125;
                  <br />
                  status: {`"${webHookOutPut?.status}"`}
                </pre>
              )}
            </div>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};
export default SettingsPage;
