import API from "@/plugins/api";
/**
 * Adds two numbers together.
 */
class ExpiryDatesService {
  // eslint-disable-next-line require-jsdoc
  all(payload: { exchangeName: string, productName: string }) {
    return API.get(`/expiry-dates?exchangeName=${payload.exchangeName}&productName=${payload.productName}`);
  }

}
export default new ExpiryDatesService();
