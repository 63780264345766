import API from "@/plugins/api";
/**
 * Adds two numbers together.
 */
class PlansService {
  // eslint-disable-next-line require-jsdoc
  all() {
    return API.get(`/plans`);
  }

  // eslint-disable-next-line require-jsdoc
  getPlanById(id: number) {
    return API.get(`/plans/${id}`);
  }
}
export default new PlansService();
