import { IProfileData } from "@/@types/common";
import { useAppSelector } from "@/hooks";
import authService from "@/service/auth.service";
import { userSelector } from "@/store/reducers/user.reducer";
import { Card, Image, Spin } from "antd";
import { useEffect, useState } from "react";

const UserProfilePage = (props: any) => {
  const { currentUser } = useAppSelector(userSelector);
  const [profilesData, setProfilesData] = useState<IProfileData>();
  const [loading, setLoading] = useState(true);

  const profileData = async () => {
    try {
      const { data, status } = await authService.profile();
      if (status === 200) {
        setProfilesData(data);
        setLoading(false);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    document.title = `${props?.title}`;
    profileData();
  }, []);
  return loading ? (
    <Spin
      spinning={loading}
      delay={0}
      tip="Loading..."
      size="large"
      className="d-flex justify-content-center align-items-center"
      style={{
        maxHeight: "100vh",
        height: "100vh",
      }}
    ></Spin>
  ) : (
    <Card className="h-100">
      <div className="card p-2 mb-2 myCard-bg">
        <div className="chartHeadding p-3 d-flex justify-content-between align-items-center mb-2">
          <div className="workDurationDiv d-flex justify-content-between align-items-center w-100">
            <h6 className="m-0">Profile</h6>
            <div className="d-flex gap-1 align-items-start justify-content-between myRadioBtn d-none"></div>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-sm-8">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-sm-4 m-auto text-center">
                <Image preview={false} width={150} src="/profile.png" alt="" />
                {/* <Icon component={() => <UserIcon width={130} height={130} />} /> */}
                <p className="m-0">
                  <b>
                    {profilesData?.firstName} &nbsp;
                    {currentUser?.lastName}
                  </b>
                </p>
                <p
                  className="m-0"
                  style={{ color: "#4D4D4D", fontSize: "14px" }}
                >
                  {profilesData?.email}
                </p>
                {/* <p
                  className="m-0"
                  style={{ color: "#4D4D4D", fontSize: "14px" }}
                >
                  {currentUser?.userEmail}
                </p> */}
              </div>
              <div className="col-sm-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Name :</p>
                  <p className="w-65">
                    {profilesData?.firstName && profilesData?.firstName}{" "}
                    {profilesData?.lastName && profilesData?.lastName}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Mobile Number :</p>
                  <p className="w-65">{profilesData?.phoneNumber}</p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Plan :</p>
                  <p className="w-65">{profilesData?.plan?.name}</p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Roles :</p>
                  <p className="w-65">{profilesData?.role?.name}</p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Address :</p>
                  <p className="w-65">{profilesData?.address}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default UserProfilePage;
