import { Card } from "antd";
import { Fragment, useEffect, useState } from "react";
import { numbersToFormat } from "@/common";
import { IPlans, PlanFeature } from "@/@types/common";
import PageHeading from "@/components/PageHeading";
import { FaGem, FaPaperPlane, FaRocket } from "react-icons/fa";
import PlansService from "@/service/plan.service";
import { useAppSelector } from "@/hooks";
import { userSelector } from "@/store/reducers/user.reducer";

const PlansListPage = (props: any) => {
  const { currentUser } = useAppSelector(userSelector);
  const [plansData, setPlansData] = useState<IPlans[]>([]);
  const planData = async () => {
    try {
      const { data, status } = await PlansService.all();
      if (status === 200) {
        setPlansData(data);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    planData();
  }, []);

  return (
    <Fragment>
      <Card
        className="UsersMainDiv campaignMainDiv"
        title={`${props?.title} (${
          plansData?.length > 0 ? numbersToFormat(plansData?.length) : 0
        })`}
        extra={
          <div className="d-flex justify-content-start align-items-center gap-2">
            <PageHeading showButton={false} />
          </div>
        }
        bordered={true}
      >
        <section className="pricing-section p-3">
          <div className="outer-box">
            <div className="row m-0">
              {plansData.map((plan: IPlans, index: number) => (
                <div
                  key={index}
                  className="pricing-block m-0 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                  data-wow-delay={
                    index === 1 ? "400ms" : index === 2 ? "800ms" : ""
                  }
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <div className="icon-outer">
                        <div className="iconsMy">
                          {index === 1 ? (
                            <FaGem />
                          ) : index === 2 ? (
                            <FaRocket />
                          ) : (
                            <FaPaperPlane />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="price-box">
                      <div className="title"> {plan?.name}</div>
                      <h4 className="price">
                        {plan?.currencySymbol}
                        {plan?.price}/
                        <small className="small-Text">
                          {plan?.billingCycle}
                        </small>
                      </h4>
                    </div>
                    <ul className="features">
                      {plan.planFeatures.length > 0 &&
                        plan.planFeatures.map(
                          (feature: PlanFeature, index: number) => {
                            return (
                              <li key={index} className="true">
                                {feature.name}
                              </li>
                            );
                          }
                        )}
                    </ul>
                    <div className="btn-box">
                      <span className="theme-btn">
                        {currentUser.planId === plan.id
                          ? "Current Plan"
                          : "Upgrade plan"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* <div className="p-2 campaignTableScreen">
          <DataTable
            className="campaignDataTable"
            loading={loading}
            columns={agentColumns}
            uniqueKey={"userUuid"}
            dataSource={apiListData}
            pagination={pagination}
            scroll={{ y: "calc(100vh - 280px)", x: "1000px" }}
            pageSize={10}
          />
        </div> */}
      </Card>
    </Fragment>
  );
};
export default PlansListPage;
