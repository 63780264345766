// ** Redux Imports
import { combineReducers } from "redux";
import { userReducer } from "./user.reducer";
import { marketWatchReducer } from "./marketWatch.reducer";
import { socketReducer } from "./socketReducer";
// ** Reducers Imports
const rootReducer = combineReducers({
  user: userReducer,
  marketWatch: marketWatchReducer,
  socket: socketReducer
});

export default rootReducer;
