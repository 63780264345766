import { Button } from "antd";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrimaryButtons = (props: any) => {
  return (
    <Button
      tabIndex={props.tabIndex}
      className={`d-flex justify-content-center align-items-center ${props.className}`}
      type={props.type}
      size={props.size}
      shape={props.shape}
      icon={props.icon}
      disabled={props.disabled}
      htmlType={props.htmlType}
      loading={props.loading}
      onClick={props.onClick}
    >
      {props.name}
    </Button>
  );
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomButtons = (props: any) => {
  return (
    <Button
      className={`d-flex align-items-center ${
        props.className && props.className
      }`}
      type={props.type}
      size={props.size}
      shape={props.shape}
      icon={props.icon}
      loading={props.loading}
    >
      {props.name}
    </Button>
  );
};
export { PrimaryButtons, CustomButtons };
