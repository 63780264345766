import { Form } from "antd";
import { PrimaryButtons } from "./Button";
import { InputBox, InputPasswordBox } from "./InputBox";
import ModalBoxs from "./Modal";
import authService from "@/service/auth.service";
import { IRegisterForm } from "@/@types/common";
import { useAppDispatch } from "@/hooks";
import { loginUser } from "@/store/reducers/user.reducer";
import { useNavigate } from "react-router";

const AddRegisterForm = (props: any) => {
  const { modalOpen, setModalOpen, setPlanId, planId } = props;
  const [form] = Form.useForm();
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const registerData = async (payload: IRegisterForm) => {
    try {
      const { status } = await authService.create(payload);
      if (status === 201) {
        const loginData: any = {
          email: payload.email,
          password: payload.password,
        };
        dispatch(loginUser(loginData))
          .then(() => {
            nav("/");
          })
          .catch((err) => {
            console.log(err);
          });
        setModalOpen(false);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  const onFinish = (values: IRegisterForm) => {
    Object.assign(values, { planId: planId });
    registerData(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    setPlanId(null);
    console.log("Failed:", errorInfo);
  };

  return (
    <ModalBoxs
      destroyOnClose
      name={"Register"}
      open={modalOpen}
      footer={false}
      // onOK={() => onFinish}
      okText={"Save"}
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        preserve={false}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        className="border-top pt-3"
      >
        <InputBox
          name={"firstName"}
          label="First Name"
          type="text"
          placeholder={"Enter firstName"}
          errorMessage={"Please enter firstName"}
          required={true}
          rules={undefined}
        />
        <InputBox
          name={"lastName"}
          label="Last Name"
          type="text"
          placeholder={"Enter lastName"}
          errorMessage={"Please enter lastName"}
          required={true}
          rules={undefined}
        />
        <InputBox
          name={"email"}
          label="Username"
          type="text"
          rules={[{ required: true, message: "Please input your username!" }]}
        />
        <InputPasswordBox
          name={"password"}
          label="Password"
          type="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        />
        <InputBox
          name={"phoneNumber"}
          label="Mobile number"
          type="number"
          placeholder={"Enter mobile number"}
          errorMessage={"Please enter mobile number"}
          required={true}
          rules={undefined}
        />

        <Form.Item className="m-auto d-flex justify-content-start align-items-center">
          <PrimaryButtons
            type="primary"
            size="large"
            htmlType="submit"
            name="Save"
            loading={false}
          />
        </Form.Item>
      </Form>
    </ModalBoxs>
  );
};
export default AddRegisterForm;
