import { ISubscriptionPayload } from "@/@types/common";
import API from "@/plugins/api";
/**
 * Adds two numbers together.
 */
class SubscriptionsService {
  // eslint-disable-next-line require-jsdoc
  create(payload: ISubscriptionPayload) {
    return API.post(`/subscriptions/subscribe`, payload);
  }
  // eslint-disable-next-line require-jsdoc
  unSubscribe(payload: ISubscriptionPayload) {
    return API.post(`/subscriptions/unSubscribe`, payload);
  }

  // eslint-disable-next-line require-jsdoc
  all() {
    return API.get(`/subscriptions`);
  }

  // eslint-disable-next-line require-jsdoc
  getPlanById(id: number) {
    return API.get(`/plans/${id}`);
  }
}
export default new SubscriptionsService();
