import { Card, Space, Tooltip } from "antd";
import { Fragment, useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router";
import { DataTable } from "@/components/Table";
import { numbersToFormat, pageSizeOptions } from "@/common";
import { IApiListData } from "@/@types/common";
import PageHeading from "@/components/PageHeading";
import authService from "@/service/auth.service";

const ApiListPage = (props: any) => {
  // const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [apiListData, setApiListData] = useState<IApiListData[]>([]);
  const [loading, setLoading] = useState(true);

  const apiData = async () => {
    try {
      const { data, status } = await authService.getAllApis();
      if (status === 200) {
        setApiListData(data);
        setLoading(false);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };
  const addApiKey = async () => {
    try {
      const { status } = await authService.createApiKey();
      if (status === 200) {
        apiData();
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    document.title = `${props?.title}`;
    apiData();
  }, [offset, limit]);

  // const displayLeadsById = (id: string) => {
  //   navigate(`/admin/user/${id}/edit`);
  // };
  useEffect(() => {
    if (apiListData?.length) {
      setTotal(apiListData?.length);
    }
  }, [apiListData]);

  const myFunction = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const agentColumns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "userUuid",
      width: 100,
      fixed: "right",
      render: (text: string, item: { id: string; key: string }) => (
        <Space
          size="middle"
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <Tooltip title="View" color={"geekblue"}>
            <div
              className="iconDivHeightWidth bg-success rounded-circle cursor-pointer"
              onClick={() => myFunction(item.key)}
            >
              <EyeOutlined className="text-white" />
            </div>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: false,
    pageSizeOptions: pageSizeOptions,
    onChange: (offset: number) => {
      setOffset(offset);
    },
    onShowSizeChange: (_current: number, limit: number) => {
      setLimit(limit);
    },
    total: total,
    current: offset,
    limit: limit,
    showTotal: (total: number, range: Array<number>) =>
      `${range[0]} to ${range[1]} of ${total}`,
  };

  return (
    <Fragment>
      <Card
        className="h-100 UsersMainDiv campaignMainDiv"
        title={`API (${
          apiListData?.length > 0 ? numbersToFormat(apiListData?.length) : 0
        })`}
        extra={
          <div className="d-flex justify-content-start align-items-center gap-2">
            <PageHeading
              showButton={true}
              buttonName="Add API key"
              search={false}
              onClick={() => addApiKey()}
            />
          </div>
        }
        bordered={true}
      >
        <div className="p-2 campaignTableScreen">
          <DataTable
            className="campaignDataTable"
            loading={loading}
            columns={agentColumns}
            uniqueKey={"userUuid"}
            dataSource={apiListData}
            pagination={pagination}
            scroll={{ y: "calc(100vh - 280px)", x: "1000px" }}
            pageSize={10}
          />
        </div>
      </Card>
    </Fragment>
  );
};
export default ApiListPage;
