import { PendingAction, RejectedAction, RootState } from "@/store";
import {
  createAsyncThunk,
  createSlice,
  AnyAction,
  PayloadAction,
  // PayloadAction,
} from "@reduxjs/toolkit";
import AuthService from "@/service/auth.service";
import { ICustomerCredentials, IUserData, IUsers } from "@/@types/common";
import { notification } from "antd";

export const loginUser = createAsyncThunk(
  "users/login",
  async (credentials: ICustomerCredentials, { rejectWithValue }) => {
    try {
      const { status, data } = await AuthService.login(credentials);
      return { status, data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.message.length > 0)
        return rejectWithValue(error.response.data.message[0]);
      else return rejectWithValue(error.response.data.message);
    }
  }
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const whoamiUser: any = createAsyncThunk(
  "users/whoami",
  async (_arm, { rejectWithValue }) => {
    try {
      const { status, data } = await AuthService.whoami();
      return { status, data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.message.length > 0)
        return rejectWithValue(error.response.data.message[0]);
      else return rejectWithValue(error.response.data.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "users/logout",
  async (_arm, { rejectWithValue }) => {
    try {
      const { status, data } = await AuthService.logout();
      return { status, data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.message.length > 0)
        return rejectWithValue(error.response.data.message[0]);
      else return rejectWithValue(error.response.data.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/create",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (Payload: any, { rejectWithValue }) => {
    try {
      const { status, data } = await AuthService.create(Payload);
      // dispatch(getAllUsers());
      return { status, data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.message.length > 0)
        return rejectWithValue(error.response.data.message[0]);
      else return rejectWithValue(error.response.data.message);
    }
  }
);

export const getUserById = createAsyncThunk(
  "users",
  async (Uuid: string, { rejectWithValue }) => {
    try {
      const { status, data } = await AuthService.getUserById(Uuid);
      return { status, data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.message.length > 0)
        return rejectWithValue(error.response.data.message[0]);
      else return rejectWithValue(error.response.data.message);
    }
  }
);

export const getDeleteUserById = createAsyncThunk(
  "users/delete",
  async (Uuid: string, { rejectWithValue }) => {
    try {
      const { status, data } = await AuthService.getDeleteUserById(Uuid);
      // dispatch(getAllUsers());
      return { status, data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.message.length > 0)
        return rejectWithValue(error.response.data.message[0]);
      else return rejectWithValue(error.response.data.message);
    }
  }
);

interface State {
  loggedIn: boolean;
  whomiLoading: boolean;
  dialerLoggedIn: boolean;
  loading: boolean;
  error: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: any;
  getAllUsersList: IUserData;
  getUsersListById: IUsers | null;
  useWebSoftPhone: boolean;
}

const initialState: State = {
  loggedIn: false,
  dialerLoggedIn: false,
  loading: false,
  whomiLoading: false,
  error: undefined,
  currentUser: undefined,
  getAllUsersList: {
    count: 0,
    rows: [],
  },
  getUsersListById: null,
  useWebSoftPhone: Boolean(localStorage.getItem("use-web-soft-phone")) ?? false,
};

const isPendingAction = (action: AnyAction): action is PendingAction =>
  action.type.startsWith(usersSlice.name) && action.type.endsWith("/pending");
const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  action.type.startsWith(usersSlice.name) && action.type.endsWith("/rejected");

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    enableWebSoftPhone(state: State, action: PayloadAction<boolean>) {
      localStorage.setItem("use-web-soft-phone", action.payload ? "1" : "0");
      state.useWebSoftPhone = action.payload;
    },
    disableWebSoftPhone(state: State, action: PayloadAction<boolean>) {
      localStorage.setItem("use-web-soft-phone", action.payload ? "1" : "0");
      state.useWebSoftPhone = action.payload;
    },
    // dialerLoginUser: (state: State, action: PayloadAction<any>) => {
    //   whoamiUser();
    //   return {
    //     ...state,
    //     dialerLoggedIn: true,
    //     currentUser: {
    //       ...state.currentUser,
    //       campaignId: action.payload.campaignId,
    //     },
    //   };
    // },
    // dialerLogOutUser: (state: State, action: PayloadAction<any>) => {
    //   return {
    //     ...state,
    //     dialerLoggedIn: initialState.dialerLoggedIn,
    //     currentUser: {
    //       ...state.currentUser,
    //       ...action.payload,
    //     },
    //   };
    // },
    // logout: (state: State) => {
    //   return {
    //     ...state,
    //     loggedIn: false,
    //     dialerLoggedIn: false,
    //     currentUser: initialState.currentUser,
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action.payload.data.statusCode === 200) {
          // notification.open({
          //   message: "User Login",
          //   description: action.payload.data.message,
          //   type: "success",
          // });
          return {
            ...state,
            whomiLoading: false,
            loading: false,
            loggedIn: true,
            resLoading: false,
            userLoading: false,
            currentUser: {
              ...action.payload.data.data,
            },
          };
        } else {
          return {
            ...state,
            whomiLoading: false,
            loading: false,
            loggedIn: false,
            resLoading: false,
            userLoading: false,
          };
        }
      })
      .addCase(loginUser.rejected, (state) => {
        notification.open({
          message: "User Login Failed",
          description: "User Unauthorized",
          type: "error",
        });
        return {
          ...state,
          whomiLoading: false,
          loading: false,
          loggedIn: false,
          resLoading: false,
          userLoading: false,
        };
      })
      .addCase(whoamiUser.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          return {
            ...state,
            whomiLoading: false,
            loggedIn: true,
            resLoading: false,
            userLoading: false,
            currentUser: {
              ...action.payload.data,
            },
          };
        } else {
          return {
            ...state,
            whomiLoading: false,
            loggedIn: false,
            resLoading: false,
            userLoading: false,
          };
        }
      })
      .addCase(whoamiUser.rejected, (state) => {
        return {
          ...state,
          whomiLoading: false,
          loggedIn: false,
          resLoading: false,
          userLoading: false,
        };
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        if (action.payload.data.statusCode === 200) {
          // notification.open({
          //   message: "User logout",
          //   description: action.payload.data.message,
          //   type: "success",
          // });
          return {
            ...state,
            whomiLoading: false,
            loading: false,
            loggedIn: false,
            dialerLoggedIn: false,
            currentUser: initialState.currentUser,
          };
        } else {
          return {
            ...state,
            whomiLoading: false,
            loading: false,
            loggedIn: false,
            dialerLoggedIn: false,
            currentUser: initialState.currentUser,
          };
        }
      })
      .addCase(logoutUser.rejected, (state) => {
        notification.open({
          message: "User logout Failed",
          description: "User Unauthorized",
          type: "error",
        });
        return {
          ...state,
          whomiLoading: false,
          loading: false,
          loggedIn: false,
          dialerLoggedIn: false,
          currentUser: initialState.currentUser,
        };
      })
      .addCase(createUser.fulfilled, (state, action) => {
        if (action.payload.data.statusCode == 201) {
          notification.open({
            message: "User Created",
            description: action.payload.data.message,
            type: "success",
          });
        }
        return { ...state, loading: false };
      })
      .addCase(createUser.rejected, (state, action) => {
        notification.open({
          message: "User Created Failed",
          description: `${action?.payload}`,
          type: "error",
        });
        return { ...state, loading: false };
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          getUsersListById: action.payload.data.data,
        };
      })
      .addCase(getUserById.rejected, (state) => {
        notification.open({
          message: "User Failed ",
          description: "User Unauthorized",
          type: "error",
        });
        return { ...state, loading: false };
      })
      .addCase(getDeleteUserById.fulfilled, (state, action) => {
        if (action.payload.data.statusCode == 200) {
          notification.open({
            message: "User Delete",
            description: action.payload.data.message,
            type: "success",
          });
        }
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getDeleteUserById.rejected, (state) => {
        notification.open({
          message: "User Delete Failed",
          description: "User Unauthorized",
          type: "error",
        });
        return { ...state, loading: false };
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = true;
        state.whomiLoading = true;
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = false;
        state.whomiLoading = false;
      });
  },
});
export const userReducer = usersSlice.reducer;
export const userSelector = (state: RootState) => state.user;
export const { disableWebSoftPhone, enableWebSoftPhone } = usersSlice.actions;
