import { Modal } from "antd";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalBoxs = (props: any) => {
  return (
    <Modal
      // {...props.destroyOnClose}
      destroyOnClose
      title={props.name}
      style={{
        top: 20,
      }}
      centered={props.centered ? true : false}
      className={props.className && props.className}
      width={props.width}
      open={props.open}
      okText={props.okText}
      footer={props.footer}
      onOk={props.onSave}
      onCancel={props.onCancel}
    >
      {props.children}
    </Modal>
  );
};
export default ModalBoxs;
