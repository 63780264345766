import { Card, Form, Space, Tooltip, notification } from "antd";
import { Fragment, useEffect, useState } from "react";
import { DataTable } from "@/components/Table";
import { pageSizeOptions } from "@/common";
import { IPlans, ISubscriptionPayload, PlanFeature } from "@/@types/common";
import PlansService from "@/service/plan.service";
import { useAppSelector } from "@/hooks";
import { userSelector } from "@/store/reducers/user.reducer";
import moment from "moment";
import subscriptionsService from "@/service/subscriptions.service";
import { PrimaryButtons } from "@/components/Button";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import MarketWatchFilter from "@/components/MarketWatchFilter";

interface IGetSubscriptionData {
  identifier: string;
  createdAt: string;
  instruments: {
    productName: string;
    expiry: string;
    exchange: {
      name: string;
    };
  };
}

const SubscriptionPage = (props: any) => {
  const [form] = Form.useForm();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const { currentUser } = useAppSelector(userSelector);
  const [getPlansByIdData, setGetPlansByIdData] = useState<IPlans>();
  const [getSubscriptionData, setGetSubscriptionData] = useState<
    IGetSubscriptionData[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [createSubLoading, setCreateSubLoading] = useState(false);
  const [unSubLoading, setUnSubLoading] = useState(false);

  const getPlanByIdData = async (id: number) => {
    try {
      const { data, status } = await PlansService.getPlanById(id);
      if (status === 200) {
        setGetPlansByIdData(data);
        setLoading(false);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  const subscriptionData = async () => {
    try {
      const { data, status } = await subscriptionsService.all();
      if (status === 200) {
        setGetSubscriptionData(data?.rows);
        setTotal(data?.count);
        setLoading(false);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  const createSubscriptionData = async (payload: ISubscriptionPayload) => {
    try {
      const { data, status } = await subscriptionsService.create(payload);
      if (status === 200) {
        console.log(data);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  const unSubscriptionData = async (payload: ISubscriptionPayload) => {
    try {
      const { status } = await subscriptionsService.unSubscribe(payload);
      if (status === 201) {
        setLoading(false);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    document.title = `${props?.title}`;
    subscriptionData();
    if (currentUser.planId) {
      getPlanByIdData(currentUser.planId);
    }
  }, [currentUser]);

  const agentColumns = [
    {
      title: "Identifier",
      dataIndex: "identifier",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      width: 150,
      ellipsis: true,
      render: (_text: string, item: IGetSubscriptionData) => (
        <span>{item?.instruments?.productName ?? "-"}</span>
      ),
    },
    {
      title: "Exchange Name",
      dataIndex: "exchange",
      width: 150,
      ellipsis: true,
      render: (_text: string, item: IGetSubscriptionData) => (
        <span>{item?.instruments?.exchange?.name ?? "-"}</span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      width: 150,
      ellipsis: true,
      render: (text: string) =>
        text ? moment(text).format("DD MMM YYYY") : "-",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry",
      width: 150,
      ellipsis: true,
      render: (_text: string, item: IGetSubscriptionData) =>
        item?.instruments?.expiry
          ? moment(item?.instruments?.expiry).format("DD MMM YYYY")
          : "-",
    },
    {
      title: "Action",
      dataIndex: "identifier",
      width: 100,
      fixed: "right",
      render: (_text: string, item: IGetSubscriptionData) => (
        <Space
          size="middle"
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <Tooltip title="un-Subscribe" color={"red"}>
            <div
              className="iconDivHeightWidth bg-danger rounded-circle cursor-pointer"
              onClick={() => {
                const btn = (
                  <Space>
                    <PrimaryButtons
                      type="primary"
                      size="middle"
                      htmlType="submit"
                      name="Yes"
                      loading={unSubLoading}
                      onClick={() => {
                        if (item) {
                          setUnSubLoading(true);
                          const payload = {
                            exchangeName: item?.instruments?.exchange?.name,
                            productName: item.instruments.productName,
                            expiryDate: item.instruments.expiry,
                          };
                          unSubscriptionData(payload)
                            .then((res: any) => {
                              setUnSubLoading(false);
                              notification.destroy("alert");
                              subscriptionData();
                            })
                            .catch((err) => {
                              setUnSubLoading(false);
                              console.log(err);
                            });
                        }
                      }}
                    />
                    <PrimaryButtons
                      type="link"
                      size="middle"
                      htmlType="submit"
                      name="No"
                      loading={unSubLoading}
                      onClick={() => {
                        notification.destroy("alert");
                      }}
                    />
                  </Space>
                );
                notification.open({
                  message: `${item.identifier} Alert.`,
                  description: `Are you sure you want to unSubscribe ${item.identifier}?`,
                  btn,
                  placement: "top",
                  type: "warning",
                  key: "alert",
                });
              }}
            >
              <DeleteOutlined className="text-white" />
            </div>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: false,
    pageSizeOptions: pageSizeOptions,
    onChange: (offset: number) => {
      setOffset(offset);
    },
    onShowSizeChange: (_current: number, limit: number) => {
      setLimit(limit);
    },
    total: total,
    current: offset,
    limit: limit,
    showTotal: (total: number, range: Array<number>) =>
      `${range[0]} to ${range[1]} of ${total}`,
  };

  const onFinish = (values: ISubscriptionPayload) => {
    setCreateSubLoading(true);
    createSubscriptionData(values)
      .then(() => {
        form.resetFields();
        setCreateSubLoading(false);
        subscriptionData();
      })
      .catch((error) => {
        setCreateSubLoading(false);
        console.log(error);
      });
  };

  const onFinishFailed = () => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Card
        className="UsersMainDiv campaignMainDiv"
        title={
          <div className="d-flex align-items-start justify-content-start w-100">
            <span className="w-100 border-end pe-3">Subscription</span>
            <span className="w-100 px-2">Plan Features</span>
          </div>
        }
        bordered={true}
      >
        <div className="subscription-detail">
          <div className="px-3 mb-2 border-bottom">
            <div className="d-flex align-items-start justify-content-start">
              <div className="w-100 p-2 border-end">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Plan Name</p>
                  <p className="w-65">{getPlansByIdData?.name ?? "-"}</p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Price</p>
                  <p className="w-65">
                    {getPlansByIdData?.currencySymbol}{" "}
                    {getPlansByIdData?.price ?? "-"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Billing Cycle</p>
                  <p className="w-65">
                    {getPlansByIdData?.billingCycle ?? "-"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Duration</p>
                  <p className="w-65">
                    {getPlansByIdData?.durationDays ?? "0"} days
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p className="w-35">Plan Purchase Date</p>
                  <p className="w-65">
                    {getPlansByIdData?.createdAt
                      ? moment(getPlansByIdData?.createdAt).format(
                          "DD MMM YYYY"
                        )
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="w-100 p-2">
                {/* <h6>Plan Features</h6> */}
                <ul className="mb-1">
                  {getPlansByIdData?.planFeatures.map(
                    (featureData: PlanFeature, index: number) => {
                      return (
                        <li key={index} className="true">
                          {featureData.name}
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Form
            form={form}
            name="basic"
            initialValues={{
              market: null,
            }}
            autoComplete="off"
            layout="vertical"
            className="d-flex justify-content-start px-3 pt-2 gap-2 align-items-center w-50"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <MarketWatchFilter />
            <Form.Item className="mb-0 mt-3 pt-1">
              <PrimaryButtons
                type="primary"
                size="middle"
                htmlType="submit"
                icon={<PlusOutlined />}
                name={"Subscribe"}
                // onClick={() => setSaveEndCall(true)}
                loading={createSubLoading}
              />
            </Form.Item>
          </Form>
          <div className="p-2 campaignTableScreen">
            <DataTable
              className="campaignDataTable"
              loading={loading}
              columns={agentColumns}
              uniqueKey={"userUuid"}
              dataSource={getSubscriptionData ?? []}
              pagination={pagination}
              scroll={{ y: "calc(100vh - 280px)", x: "1000px" }}
              pageSize={10}
            />
          </div>
        </div>
      </Card>
    </Fragment>
  );
};
export default SubscriptionPage;
