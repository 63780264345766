import { Fragment, useEffect, useState } from "react";
import { Layout, Spin, theme } from "antd";
import { useAppDispatch, useAppSelector } from "../hooks";
import { userSelector, whoamiUser } from "../store/reducers/user.reducer";
import Login from "./Login";
import AdminRoutes from "../config/AdminRoutes";
import Register from "./Register";

const { Content } = Layout;

const LayoutBox = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const { loggedIn } = useAppSelector(userSelector);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dispatch(whoamiUser());
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <Fragment>
      {loading ? (
        <Spin
          spinning={loading}
          delay={0}
          tip="Loading..."
          size="large"
          className="d-flex justify-content-center align-items-center"
          style={{
            maxHeight: "100vh",
            height: "100vh",
          }}
        ></Spin>
      ) : loggedIn ? (
        <AdminRoutes />
      ) : (
        <Content
          style={{
            background: colorBgContainer,
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            margin: "auto",
            height: "100vh",
          }}
        >
          {window.location.pathname === "/register" ? <Register /> : <Login />}
        </Content>
      )}
    </Fragment>
  );
};
export default LayoutBox;
