import axios from "axios";

// export default axios.create({ baseURL: "http://61.12.85.62:9000/api/v1" });
// console.log("process.env.REACT_APP_BASE_URL", process.env.REACT_APP_BASE_URL);
// export default axios.create({
//   baseURL:
//     process.env.NODE_ENV === "development"
//       ? process.env.REACT_APP_BASE_URL
//       : "/api/v1",
//   withCredentials: true,
// });
const ssoIntance = axios.create({
  baseURL: `/api/v1`,
  withCredentials: true,
});

ssoIntance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const sources = error?.response ?? error;

    if (sources?.status === 403 && sources.config.url !== "/auth/whoami") {
      window.localStorage.clear();
      deleteAllCookies();
      window.location.href = "/login";
      throw Error("Unauthorization error");
    }
    return Promise.reject(error);
  }
);

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export default ssoIntance;
// export default axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

// export default axios.create({
//   baseURL:
//     process.env.NODE_ENV === "development"
//       ? process.env.REACT_APP_BASE_URL
//       : "/api/v1",
// });

// API.interceptors.response.use((response)=>{
//   return Promise.resolve(response)
// },(error)=>{
//   window.location.replace('/login')
//   return Promise.reject(error)
// })

export const createInstance = () => axios.create({ baseURL: "/api" });
