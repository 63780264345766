import { IMarketWatchListData } from '@/@types/common';
import marketWatchService from '@/service/marketWatch.service';
import { PendingAction, RejectedAction, RootState } from '@/store';
import { createAsyncThunk, createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';

export const getAllMarketWatchData = createAsyncThunk('market-watch/all', async (_arg, { rejectWithValue }) => {
  try {
    const { status, data } = await marketWatchService.all();
    return { status, data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.message.length > 0) return rejectWithValue(error.response.data.message[0]);
    else return rejectWithValue(error.response.data.message);
  }
});

interface State {
  loading: boolean;
  error: string | undefined;
  marketWatchListData: IMarketWatchListData[];
}

const initialState: State = {
  loading: false,
  error: undefined,
  marketWatchListData: [],
};

const isPendingAction = (action: AnyAction): action is PendingAction => action.type.startsWith(marketWatchSlice.name) && action.type.endsWith('/pending');
const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  action.type.startsWith(marketWatchSlice.name) && action.type.endsWith('/rejected');

const marketWatchSlice = createSlice({
  name: 'marketWatch',
  initialState,
  reducers: {
    socketRealTimeUpdates: (
      state: State,
      action: PayloadAction<IMarketWatchListData>
    ) => {
      if (action.payload) {
        const newMarketList = JSON.parse(JSON.stringify(state.marketWatchListData));
        const rowIndex = newMarketList.findIndex(
          (e: IMarketWatchListData) =>
            e.InstrumentIdentifier === action.payload.InstrumentIdentifier
        );
        if (rowIndex > -1) {
          newMarketList[rowIndex] = {
            ...action.payload,
            PriceChangeWithPercentage: `${action.payload.PriceChange} (${action.payload.PriceChangePercentage})`,
          };
        }
        return {
          ...state, marketWatchListData: newMarketList.map((item: IMarketWatchListData) => {
            const name = item?.InstrumentIdentifier?.split("_");
            return {
              ...item,
              Symbol: `${name[1]}_${name[2]}`,
            };
          })
        };
      } else {
        return { ...state };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMarketWatchData.fulfilled, (state, action) => {
        console.log('action.payload--->', action.payload)
        if (action.payload.status === 200) {
          const changeData = action.payload.data.map((item: IMarketWatchListData) => {
            const name = item?.InstrumentIdentifier?.split("_");
            const PriceChangeWithPercentage = `${item.PriceChange} (${item.PriceChangePercentage})`;
            return {
              ...item,
              Symbol: `${name[1]}_${name[2]}`,
              PriceChangeWithPercentage: PriceChangeWithPercentage,
            };
          });
          return {
            ...state,
            loading: false,
            marketWatchListData: changeData,
          };
        } else {
          return {
            ...state,
            loading: false,
            marketWatchListData: initialState.marketWatchListData,
          };
        }
      })
      .addCase(getAllMarketWatchData.rejected, (state) => {
        notification.open({
          message: 'Roles Failed',
          description: 'User Unauthorized',
          type: 'error',
        });
        return { ...state, loading: false };
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = false;
      });
  },
});
export const marketWatchReducer = marketWatchSlice.reducer;

export const marketWatchSelector = (state: RootState) => state.marketWatch;
export const { socketRealTimeUpdates } = marketWatchSlice.actions;
