import API from "@/plugins/api";
/**
 * Adds two numbers together.
 */
class ExchangesService {
  // eslint-disable-next-line require-jsdoc
  all() {
    return API.get(`/exchanges`);
  }

}
export default new ExchangesService();
