import React, { Fragment } from "react";
import { Form, Input, DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { Rule } from "antd/es/form";

const { TextArea } = Input;

interface textInterface {
  label?: string;
  name: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  className?: string;
  formClassName?: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder?: any;
  maxLength?: number;
  minLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pattern?: any;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  number?: any;
  helpText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addonAfter?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any;
  min?: number;
  max?: number;
  hidden?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyPress?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyDown?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyUp?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checked?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readOnly?: any;
  minDate?: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDate?: any;
  focus?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tabIndex?: any;
  minRows?: number;
  maxRows?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prefix?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCalendarChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOpenChange?: any;
  allowClear?: boolean;
  // eslint-disable-next-line no-unused-vars
  onEnter?: (val: string) => void;
  newRules?: Rule[];
}

const InputBox = (props: textInterface) => {
  const rules = [];
  if (props.required) {
    rules.push({
      required: true,
      message: props.errorMessage,
    });
  } else {
    rules.push({
      required: false,
      message: "",
    });
  }
  if (props.rules) {
    rules.push(props.rules);
  }

  const defaultRules = [
    {
      required: props.required ? props.required : false,
      message: props.required ? props.errorMessage : "",
    },
  ];
  const combinedRules = [...defaultRules, ...(props?.newRules ?? [])];

  return (
    <Fragment>
      <Form.Item
        label={props.label}
        name={props.name}
        id={props.id}
        className={`w-100 ${
          props.formClassName ? props.formClassName : "mb-3"
        } `}
        rules={combinedRules}
        //  rules={[{ ...props.rules, required: props.required ? props.required : false, message: props.required ? props.errorMessage : '' }]}
        // rules={rules}
      >
        {/* <Input size="middle" placeholder="large size" /> */}
        <Input
          // size="small"
          ref={props.ref}
          size={props.size ? props.size : "large"}
          tabIndex={props.tabIndex}
          disabled={props.disabled}
          maxLength={props.maxLength}
          minLength={props.minLength}
          type={props.type}
          pattern={props.pattern}
          className={`${
            props.className && props.className
          } form-control w-100 ${props.size === "middle" && "lineHeight"}`}
          placeholder={props.placeholder}
          addonAfter={props.addonAfter}
          max={props.max}
          hidden={props.hidden}
          readOnly={props.readOnly}
          prefix={props.prefix}
          allowClear={props.allowClear}
          value={props.value}
          // defaultValue={props.defaultValue}
          min={props.min}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          onClick={props.onClick}
        />
      </Form.Item>
    </Fragment>
  );
};
const InputPasswordBox = (props: textInterface) => {
  const rules = [];
  if (props.required) {
    rules.push({
      required: true,
      message: props.errorMessage,
    });
  } else {
    rules.push({
      required: false,
      message: "",
    });
  }
  if (props.rules) {
    rules.push(props.rules);
  }

  return (
    <Fragment>
      <Form.Item
        label={props.label}
        name={props.name}
        className={`w-100  mb-3`}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
        //  rules={[{ ...props.rules, required: props.required ? props.required : false, message: props.required ? props.errorMessage : '' }]}
        // rules={rules}
      >
        <Input.Password
          size="middle"
          disabled={props.disabled}
          tabIndex={props.tabIndex}
          maxLength={props.maxLength}
          type={props.type}
          pattern={props.pattern}
          className={`${props.className} w-100`}
          placeholder={props.placeholder}
          addonAfter={props.addonAfter}
          value={props.value}
          defaultValue={props.defaultValue}
          min={props.min}
          max={props.max}
          hidden={props.hidden}
          readOnly={props.readOnly}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          onClick={props.onClick}
        />
      </Form.Item>
    </Fragment>
  );
};
const InputConfirmPasswordBox = (props: textInterface) => {
  // const rules = [];
  // if (props.required) {
  //   rules.push({
  //     required: true,
  //     message: props.errorMessage,
  //   });
  // } else {
  //   rules.push({
  //     required: false,
  //     message: "",
  //   });
  // }
  // if (props.rules) {
  //   rules.push(props.rules);
  // }

  return (
    <Fragment>
      <Form.Item
        label={props.label}
        name={props.name}
        className={`w-100  mb-3`}
        dependencies={["password"]}
        rules={props.rules}
        //  rules={[{ ...props.rules, required: props.required ? props.required : false, message: props.required ? props.errorMessage : '' }]}
        // rules={rules}
      >
        <Input.Password
          size="large"
          tabIndex={props.tabIndex}
          disabled={props.disabled}
          maxLength={props.maxLength}
          type={props.type}
          pattern={props.pattern}
          className={`${props.className} w-100`}
          placeholder={props.placeholder}
          addonAfter={props.addonAfter}
          value={props.value}
          defaultValue={props.defaultValue}
          min={props.min}
          max={props.max}
          hidden={props.hidden}
          readOnly={props.readOnly}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          onClick={props.onClick}
        />
      </Form.Item>
    </Fragment>
  );
};
const DateTimePicker = (props: textInterface) => {
  return (
    <Fragment>
      <Form.Item
        label={props.label}
        name={props.name}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <DatePicker
          size="large"
          name={props.name}
          className={`${props.className} form-control`}
          placeholder={props.placeholder}
          value={props.value}
          format="DD-MM-YYYY HH:mm"
          inputReadOnly={true}
          showTime={{ format: "HH:mm" }}
          disabled={props.disabled}
          onBlur={props.onBlur}
          onChange={props.onChange}
          // disabledDate={disabledDate}
          // disabledTime={disabledDateTime}
          //   showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
        />
      </Form.Item>
    </Fragment>
  );
};

const DatePickerD = (props: textInterface) => {
  const dateFormat = "DD-MM-YYYY";

  return (
    <Fragment>
      <Form.Item
        className={`${props.className && props.className} w-100`}
        label={props.label}
        name={props.name}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <DatePicker
          size={props.size ? props.size : "large"}
          name={props.name}
          className={`${props.className} form-control`}
          placeholder={props.placeholder}
          value={props.value}
          format="DD-MM-YYYY"
          inputReadOnly={true}
          disabled={props.disabled}
          defaultValue={dayjs(props.defaultValue, dateFormat)}
          disabledDate={props.disabledDate}
          onBlur={props.onBlur}
          onChange={props.onChange}
          // maxDate={props.maxDate}
          // disabledDate={range}
          // range={range}
          // minDate = {new Date()}
          // defaultPickerValue={dayjs(props.defaultValue, dateFormat)}
          // defaultValue={props.defaultValue}
          // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
        />
      </Form.Item>
    </Fragment>
  );
};
const RangePickerD = (props: textInterface) => {
  // const dateFormat = "DD-MM-YYYY";
  const { RangePicker } = DatePicker;

  return (
    <Fragment>
      <Form.Item
        className={`${props.className && props.className}`}
        label={props.label}
        name={props.name}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <RangePicker
          size={props.size ? props.size : "large"}
          name={props.name}
          className={`w-100`}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          disabledDate={props.disabledDate}
          value={props.value}
          onBlur={props.onBlur}
          // format={"YYYY/MM/DD"}
          // value={dates || value}
          onChange={props.onChange}
          onCalendarChange={props.onCalendarChange}
          onOpenChange={props.onOpenChange}
          // onChange={(val) => {
          //   setValue(val);
          // }}
        />
        {/* <DatePicker
          size={props.size ? props.size : "large"}
          name={props.name}
          onBlur={props.onBlur}
          onChange={props.onChange}
          className={`${props.className} form-control`}
          placeholder={props.placeholder}
          value={props.value}
          format="DD-MM-YYYY"
          inputReadOnly={true}
          defaultValue={dayjs(props.defaultValue, dateFormat)}
          disabledDate={props.disabledDate}
          // maxDate={props.maxDate}
          // disabledDate={range}
          // range={range}
          // minDate = {new Date()}
          // defaultPickerValue={dayjs(props.defaultValue, dateFormat)}
          // defaultValue={props.defaultValue}
          // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
        /> */}
      </Form.Item>
    </Fragment>
  );
};

const DatePickerDisable = (props: textInterface) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    const startDate = moment(new Date(), "DD-MM-YYYY").subtract(1, "days");
    return current && current <= startDate;
  };

  return (
    <Fragment>
      <Form.Item
        label={props.label}
        name={props.name}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <DatePicker
          size="large"
          name={props.name}
          className={`${props.className} form-control`}
          placeholder={props.placeholder}
          value={props.value}
          format="DD-MM-YYYY"
          defaultValue={props.defaultValue && moment(props.defaultValue)}
          inputReadOnly={true}
          disabledDate={disabledDate}
          onBlur={props.onBlur}
          // minDate = {props.minDate}
          // defaultPickerValue={props.defaultValue}
          onChange={props.onChange}
          // disabledTime={disabledDateTime}
          // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
        />
      </Form.Item>
    </Fragment>
  );
};

// const DatePickerDisable = (props: textInterface) => {
//   function range(start: any, end: any) {
//     const result = [];
//     for (let i = start; i < end; i++) {
//       result.push(i);
//     }
//     return result;
//   }

//   function disabledDate(current: any) {
//     // Can not select days before today and today
//     const startDate = moment(new Date(), "DD-MM-YYYY").subtract(1, 'days');
//     return current && current <= startDate;
//   }

//   return (
//     <>
//       <Form.Item
//         label={props.label}
//         name={props.name}
//         rules={[{ required: props.required ? props.required : false, message: props.required ? props.errorMessage : '' }]}
//       >
//         <DatePicker
//           size="large"
//           name={props.name}
//           onBlur={props.onBlur}
//           onChange={props.onChange}
//           className={`${props.className} form-control`}
//           placeholder={props.placeholder}
//           value={props.value}
//           format="DD-MM-YYYY"
//           inputReadOnly={true}
//           // minDate = {props.minDate}
//           // defaultPickerValue={props.defaultValue}
//           defaultValue={props.defaultValue && moment(props.defaultValue)}
//           disabledDate={disabledDate}
//         // disabledTime={disabledDateTime}
//         //showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
//         />
//       </Form.Item>
//     </>
//   );
// };

const TextAreaBox = (props: textInterface) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent new line in TextArea
      props?.onEnter?.(e.target.value);
    }
  };

  return (
    <Fragment>
      <Form.Item
        label={props.label}
        name={props.name}
        className={props.formClassName}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <TextArea
          tabIndex={props.tabIndex}
          value={props.value}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          className={`${props.className} form-control rounded`}
          autoSize={{
            minRows: props.minRows,
            maxRows: props.maxRows,
          }}
          onChange={props.onChange}
          onKeyPress={handleKeyPress}
        />
      </Form.Item>
    </Fragment>
  );
};

export interface INotes {
  title: string;
  text: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FileDragAndDropUploadBox = (props: any) => {
  const {
    uploadDragData,
    showError,
    extra,
    required,
    label,
    className,
    errorMessage,
    notes,
  } = props;
  return (
    <Form.Item
      label={label}
      extra={extra}
      className={className}
      // extra={
      //   <p className="text-xs text-gray-500 m-0">
      //     <Link to="/bulk-upload-test.csv" download={"/bulk-upload-test.csv"}>
      //       Download Sample FIle
      //     </Link>
      //   </p>
      // }
      required={required}
    >
      <Dragger
        {...uploadDragData}
        className={`${(showError || errorMessage) && "draggerError"} `}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined
            className={`${(showError || errorMessage) && "text-danger"}`}
          />
        </p>
        {notes
          ? notes.map((noteData: INotes, index: number) => {
              let html;
              switch (noteData.title) {
                case "Head":
                  html = (
                    <p key={index} className="ant-upload-text">
                      {noteData.text}
                    </p>
                  );
                  break;
                case "Body":
                  html = (
                    <p key={index} className="ant-upload-hint">
                      {noteData.text}
                    </p>
                  );
                  break;
                case "Disclaimer":
                  html = (
                    <p key={index} className="text-danger">
                      <ul>
                        <li>{noteData.text}</li>
                      </ul>
                    </p>
                  );
                  break;

                default:
                  break;
              }
              return html;
            })
          : ""}
      </Dragger>
      {(showError || errorMessage) && (
        <p className="m-0 text-danger">
          {errorMessage ?? "Please upload file!"}
        </p>
      )}
    </Form.Item>
  );
};

export {
  InputBox,
  RangePickerD,
  InputPasswordBox,
  InputConfirmPasswordBox,
  TextAreaBox,
  DateTimePicker,
  DatePickerD,
  DatePickerDisable,
  FileDragAndDropUploadBox,
};
