import { Link } from "react-router-dom";
import { Fragment } from "react";
import { PrimaryButtons } from "./Button";
import {
  PlusOutlined,
  InboxOutlined,
  ClearOutlined,
  SyncOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { SearchInputBox } from "./SelectBox";
import { notification } from "antd";
import { CSVLink } from "react-csv";
import { ExportIcon } from "./Icons/CardBox";

const PageHeading = (props: any) => {
  return (
    <Fragment>
      {/* <div className="statbox widget mb-2 pb-3 box box-shadow border-bottom"> */}
      <div className="statbox widget box box-shadow">
        <div className="widget-header">
          <div className="headingDiv">
            <div className="row m-0">
              <div className="col-xl-12 col-md-12 col-sm-12 col-12 px-0 d-flex gap-2 justify-content-between align-items-center  headingContent">
                {props.title && <h5 className="m-0">{props.title}</h5>}
                {props.showButton && (
                  <div className="d-flex gap-2 searchDiv p-0 align-items-center">
                    {props.search && (
                      <SearchInputBox
                        className="w-100"
                        onSearch={props.onSearch}
                      />
                    )}
                    {props.export && (
                      <CSVLink
                        // className="btn btn-primary py-1 mb-4"
                        filename={props.filename}
                        data={props.exportData ? props.exportData : []}
                        style={{
                          color: "#fff",
                          listStyle: "none",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          notification.open({
                            message: "Agent Reports",
                            description: "This file is downloading",
                            type: "success",
                          });
                        }}
                      >
                        <PrimaryButtons
                          type="primary"
                          size="middle"
                          icon={<ExportIcon />}
                          name="Export"
                          // loading={loading}
                        />
                      </CSVLink>
                    )}
                  </div>
                )}
                <div className="d-flex gap-2 searchDiv p-0 align-items-center">
                  {props.deleteButton && (
                    <PrimaryButtons
                      className="px-2"
                      name={props.bulkDeleteButton}
                      disabled={!props.hasSelected}
                      size={"default"}
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={props.onDeleteLead}
                    />
                  )}
                  {props.SyncConfButton && (
                    <PrimaryButtons
                      className="px-2"
                      name={props.SyncConfButtonName}
                      size={"default"}
                      type="primary"
                      disabled={props.disableLeadBtn}
                      icon={<SyncOutlined />}
                      onClick={props.syncConf}
                    />
                  )}
                  {props.ClearCacheButton && (
                    <PrimaryButtons
                      className="px-2"
                      name={props.ClearCacheButtonName}
                      size={"default"}
                      type="primary"
                      disabled={props.disableLeadBtn}
                      icon={<ClearOutlined />}
                      onClick={props.clearCache}
                    />
                  )}

                  {props.link ? (
                    <Link to={props.link}>
                      <PrimaryButtons
                        className="px-2"
                        name={props.buttonName}
                        size={"default"}
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={props.onClick}
                      />
                    </Link>
                  ) : (
                    props.showButton && (
                      <PrimaryButtons
                        className="px-2"
                        name={props.buttonName}
                        size={"default"}
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={props.onClick}
                      />
                    )
                  )}
                  {props.bulkButton && (
                    <PrimaryButtons
                      className="px-2"
                      name={props.bulkButton}
                      size={"default"}
                      type="primary"
                      icon={<InboxOutlined />}
                      onClick={() => {
                        props.setModalOpen(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PageHeading;
