import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store/";
import LayoutBox from "./components/LayoutBox";
import "bootstrap/dist/css/bootstrap.min.css";

const RealTimeDataStockMarketWebApp: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <LayoutBox />
      </Router>
    </Provider>
  );
};

export default RealTimeDataStockMarketWebApp;
