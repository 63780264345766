import { SelectBox } from "./SelectBox";
import exchangesService from "@/service/exchanges.service";
import { Fragment, useEffect, useState } from "react";
import productsService from "@/service/products.service";
import expiryDatesService from "@/service/expiryDates.service";

interface IExchangesData {
  id: number;
  name: string;
}

interface IProductData {
  name: string;
  exchange: {
    name: string;
  };
  instrumentType: string | null;
}

interface IExpiryDate {
  date: string;
  exchange: {
    name: string;
  };
  instrumentType: string | null;
  product: {
    name: string;
  };
}

const MarketWatchFilter = () => {
  const [exchangesData, setExchangesData] = useState<IExchangesData[]>([]);
  const [productsData, setProductsData] = useState<IProductData[]>([]);
  const [expiryDatesData, setExpiryDatesData] = useState<IExpiryDate[]>([]);
  const [exchangeId, setExchangeId] = useState<string | null>(null);
  const [productId, setProductId] = useState<string | null>(null);

  const exchangeData = async () => {
    try {
      const { data, status } = await exchangesService.all();
      if (status === 200) {
        setExchangesData(data);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  const productData = async (value: string) => {
    try {
      const { data, status } = await productsService.all(value);
      if (status === 200) {
        setProductsData(data);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  const expiryDateData = async (value: {
    exchangeName: string;
    productName: string;
  }) => {
    try {
      const { data, status } = await expiryDatesService.all(value);
      if (status === 200) {
        setExpiryDatesData(data);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    exchangeData();
  }, []);

  useEffect(() => {
    if (exchangeId) {
      productData(exchangeId);
    }
  }, [exchangeId]);

  useEffect(() => {
    if (exchangeId && productId) {
      const payload = {
        exchangeName: exchangeId,
        productName: productId,
      };
      expiryDateData(payload);
    }
  }, [exchangeId, productId]);

  const exchangesOptions: { value: string; label: string }[] =
    exchangesData?.map((element: IExchangesData) => {
      return { value: element.name, label: element.name };
    });
  const productsOptions: { value: string; label: string }[] = productsData?.map(
    (element: IProductData) => {
      return { value: element.name, label: element.name };
    }
  );
  const expiryDateOptions: { value: string; label: string }[] =
    expiryDatesData?.map((element: IExpiryDate) => {
      return { value: element.date, label: element.date };
    });

  return (
    <Fragment>
      <SelectBox
        tabIndex={1}
        className="w-100 mb-2"
        placeholder={"Exchange"}
        options={exchangesOptions ?? []}
        size="middle"
        name="exchangeName"
        label={"Exchange"}
        errorMessage={"Please select exchange"}
        required={true}
        onChange={(event: string) => setExchangeId(event)}
      />

      <SelectBox
        tabIndex={3}
        className="w-100 mb-2"
        placeholder={"Script Name"}
        options={productsOptions ?? []}
        size="middle"
        name="productName"
        label={"Script Name"}
        errorMessage={"Please select Script Name"}
        required={true}
        onChange={(event: string) => setProductId(event)}
      />

      <SelectBox
        tabIndex={5}
        className="w-100 mb-2"
        placeholder={"Expiry Date"}
        options={expiryDateOptions ?? []}
        size="middle"
        name="expiryDate"
        label={"Expiry Date"}
        errorMessage={"Please select Expiry Date"}
        required={true}
        // onChange={(event: string) => setAgentMan(event)}
      />
    </Fragment>
  );
};
export default MarketWatchFilter;
