import { Form, Select, Input, Divider, Space, Button } from "antd";
import type { InputRef } from "antd";
import { useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

interface selectInterface {
  label?: string;
  formClassName?: string;
  name?: string;
  required?: boolean;
  errorMessage?: string;
  className?: string;
  type?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { value: string; label: string }[] | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyDown?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInputKeyDown?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onNameChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addItem?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyUp?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  disabled?: boolean;
  tabIndex?: number;
  showSearch?: boolean;
  allowClear?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdownRender?: any;
}
interface searchInputInterface {
  label?: string;
  name?: string;
  required?: boolean;
  errorMessage?: string;
  className?: string;
  type?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyUp?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  disabled?: boolean;
  tabIndex?: number;
  showSearch?: boolean;
}

const SearchSelectBox = (props: selectInterface) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onBlur = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFocus = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const children: any = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props.options.forEach((optionValue: any) => {
    children.push(
      <Option key={optionValue.value} value={optionValue.value}>
        {optionValue.label}
      </Option>
    );
  });

  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <Select
          showSearch
          placeholder={props.placeholder}
          optionFilterProp="children"
          disabled={props.disabled}
          size="large"
          defaultValue={props.defaultValue}
          className={`w-100 mySelectBox ${props.className}`}
          onChange={props.onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          //   filterOption={(input, option) =>
          //     option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          //   }
          onSearch={onSearch}
          onClick={props.onClick}
        >
          {children}
        </Select>
      </Form.Item>
    </>
  );
};

const SelectBox = (props: selectInterface) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onBlur = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFocus = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const children: any = [];
  props.options &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.options.forEach((optionValue: any) => {
      children.push(
        <Option key={optionValue.value} value={optionValue.value}>
          {optionValue.label}
        </Option>
      );
    });

  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        className={`${props.className && props.className}`}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <Select
          placeholder={props.placeholder}
          optionFilterProp="children"
          size={props.size ? props.size : "large"}
          className="w-100 mySelectBox text-start"
          disabled={props.disabled}
          tabIndex={props.tabIndex}
          // showSearch={props.showSearch}
          showSearch={true}
          allowClear={props.allowClear}
          // defaultValue={props.defaultValue}
          dropdownRender={props.dropdownRender}
          onChange={props.onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
        >
          {children}
        </Select>
      </Form.Item>
    </>
  );
};

const MultipleSelectBox = (props: selectInterface) => {
  //   const onChange = () => {};

  // const onBlur = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFocus = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const children: any = props?.options?.map((optionValue: any) => {
    return (
      <Option key={optionValue.value} value={optionValue.value}>
        {optionValue.label}
      </Option>
    );
  });

  return (
    <>
      <Form.Item
        className={`${props.formClassName ? props.formClassName : "mb-3"}`}
        name={props.name}
        label={props.label}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <Select
          showArrow
          mode="multiple"
          placeholder={props.placeholder}
          optionFilterProp="children"
          dropdownRender={props.dropdownRender}
          size={props.size}
          className={`w-100 mySelectBox ${props.className}`}
          onChange={props.onChange}
          onFocus={onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          onInputKeyDown={props.onInputKeyDown}
          onSearch={onSearch}
          //   filterOption={(input, option) =>
          //     option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          //   }
        >
          {children}
        </Select>
      </Form.Item>
    </>
  );
};
const SearchInputBox = (props: searchInputInterface) => {
  const { Search } = Input;
  // const suffix = (
  //   <AudioOutlined
  //     style={{
  //       fontSize: 16,
  //       color: '#1890ff',
  //     }}
  //   />
  // );

  return (
    <>
      <Search
        allowClear
        placeholder="Search"
        size="middle"
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        className={`${props.className}`}
        onSearch={props.onSearch}
        onKeyUp={props.onKeyUp}
        onClick={props.onClick}
      />
    </>
  );
};

const SelectAddNewOptionBox = (props: selectInterface) => {
  const inputRef = useRef<InputRef>(null);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onBlur = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFocus = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const children: any = [];
  props.options &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.options.forEach((optionValue: any) => {
      children.push(
        <Option key={optionValue.value} value={optionValue.value}>
          {optionValue.label}
        </Option>
      );
    });

  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        className={`${props.className && props.className}`}
        rules={[
          {
            required: props.required ? props.required : false,
            message: props.required ? props.errorMessage : "",
          },
        ]}
      >
        <Select
          tabIndex={props.tabIndex}
          placeholder={props.placeholder}
          optionFilterProp="children"
          size={props.size ? props.size : "large"}
          className="w-100 mySelectBox text-start"
          disabled={props.disabled}
          allowClear={props.allowClear}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input
                  ref={inputRef}
                  placeholder="Please enter item"
                  // value={name}
                  onChange={props.onNameChange}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={props.addItem}
                >
                  Add item
                </Button>
              </Space>
            </>
          )}
          options={props.options}
          onChange={props.onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
        />
        {/* <Select
          tabIndex={props.tabIndex}
          showSearch={props.showSearch}
          placeholder={props.placeholder}
          optionFilterProp="children"
          onChange={props.onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
          size={props.size ? props.size : "large"}
          className="w-100 mySelectBox text-start"
          disabled={props.disabled}
          allowClear={props.allowClear}
          // defaultValue={props.defaultValue}
        >
          {children}
        </Select> */}
      </Form.Item>
    </>
  );
};

export {
  SearchSelectBox,
  SelectBox,
  MultipleSelectBox,
  SearchInputBox,
  SelectAddNewOptionBox,
};
