import { useEffect, useState } from "react";
import { Card, Tag } from "antd";
import CardBox from "@/components/CardBox";
import { CopyOutlined } from "@ant-design/icons";
import authService from "@/service/auth.service";
import { IApiListData } from "@/@types/common";

const Home = (props: any) => {
  const [apiListData, setApiListData] = useState<IApiListData[]>([]);
  const [, setLoading] = useState(true);

  const apiData = async () => {
    try {
      const { data, status } = await authService.getAllApis();
      if (status === 200) {
        setApiListData(data);
        setLoading(false);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    document.title = `${props?.title}`;
    apiData();
  }, []);

  const myFunction = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Card
      title="Dashboard"
      className="mb-3 dashboardTopPanel site-statistic-demo-card"
    >
      <div className="row m-0">
        <div className="col-lg-4 col-sm-6 ps-0 pb-3 cardBgColor">
          <CardBox className={"custome-statistic-card"}>
            <div className="custome-statistic d-flex align-items-center">
              <div className="w-100">
                <div className="d-flex text-white justify-content-between align-items-center mb-2">
                  <span>
                    <b>API Key</b>
                  </span>
                  {/* <span>02 feb 2024 - 02 March 2024</span> */}
                </div>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div className="bg-white w-100 rounded p-2">
                    <span className="m-0">
                      {apiListData[0]?.key ?? "API key Not Available"}
                    </span>
                  </div>
                  <div onClick={() => myFunction(apiListData[0]?.key ?? "")}>
                    <span
                      title="copy"
                      className="bg-white text-primary cursor-pointer roundedCircleWidth-45 rounded-circle d-flex justify-content-center align-items-center"
                    >
                      <CopyOutlined />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CardBox>
        </div>
        <div className="col-lg-4 col-sm-6 ps-0 pb-3 cardBgColor">
          <CardBox className={"custome-statistic-card"}>
            <div className="custome-statistic d-flex align-items-center">
              <div className="w-100 text-white symbols">
                <h6 className="mb-2">Symbols</h6>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <Tag
                    color="#108ee9"
                    className="w-100 py-1 TotalDiv d-flex justify-content-between align-items-center"
                  >
                    <span className="text">
                      <b>Total</b>
                    </span>
                    <div className="count d-flex justify-content-center align-items-center">
                      200
                    </div>
                  </Tag>
                  <Tag
                    color="#87d068"
                    className="w-100 py-1 TotalDiv d-flex justify-content-between align-items-center"
                  >
                    <span className="text">
                      <b>Used</b>
                    </span>
                    <div className="count d-flex justify-content-center align-items-center">
                      10
                    </div>
                  </Tag>
                  {/* <Tag className="w-100" color="#108ee9">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        <b>Total</b>
                      </span>
                      <span>200</span>
                    </div>
                  </Tag>
                  <Tag className="w-100" color="#87d068">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        <b>Used</b>
                      </span>
                      <span>10</span>
                    </div>
                  </Tag> */}
                </div>
              </div>
            </div>
          </CardBox>
        </div>
        <div className="col-lg-4 col-sm-6 ps-0 pb-3 cardBgColor">
          <CardBox className={"custome-statistic-card"}>
            <div className="custome-statistic d-flex align-items-center">
              <div className="w-100 py-2 text-white symbols">
                <div className="py-1 d-flex justify-content-between align-items-center">
                  <h6 className="m-0">
                    <b>Expire Date</b>
                  </h6>
                  <Tag color="#2db7f5" className="px-3 py-2 text">
                    02 Feb 2024
                  </Tag>
                </div>
              </div>
            </div>
          </CardBox>
        </div>
      </div>
    </Card>
  );
  // return <ApiListPage />;
};
export default Home;
