import { Card } from "antd";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CardBox = (props: any) => {
  return (
    <Card
      title={props.title}
      bordered={props.bordered}
      className={`${props.color && props.color} ${
        props.width && `w-${props.width}`
      } ${props.className}`}
    >
      {props.children}
    </Card>
  );
};
export default CardBox;
