import { createSlice } from "@reduxjs/toolkit";

export interface SocketState {
  isEstablishingConnection: boolean;
  isConnected: boolean;
}
export interface Subscribe {
  Exchange: string;
  InstrumentIdentifier: string;
}
export interface UserStatus {
  id: number;
  status: boolean;
}

const initialState: SocketState = {
  isEstablishingConnection: false,
  isConnected: false,
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    startConnecting: (state: SocketState) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state: SocketState) => {
      state.isConnected = true;
      state.isEstablishingConnection = false;
    },
    disconnect: (state: SocketState) => {
      state.isEstablishingConnection = false;
      state.isConnected = false;
    },
  },
});

export const socketReducer = socketSlice.reducer;

export const socketActions = socketSlice.actions;

export default socketSlice;
