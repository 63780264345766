// import { useState } from "react";
import { Image, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import Icon from "@ant-design/icons";
// import { AgentLogoIcon } from "./Icons/CardBox";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SideBarBox = (props: any) => {
  const href = window.location.href.split("/");
  const path = href[4] === undefined ? href[3] : `${href[3]}/${href[4]}`;

  const navigate = useNavigate();
  return (
    <Sider
      collapsible
      className={props?.collapsed ? "AdminLeftSideBar" : "AdminLeftSideBarOpen"}
      collapsed={props?.collapsed}
      theme="light"
      onCollapse={(value) => {
        props.setCollapsed(value);
      }}
    >
      <div className="d-flex flex-column h-100">
        <div className="mb-auto">
          <div className="logo py-1">
            <h3
              className={`text-primary-header ${
                props?.collapsed ? "px-3 py-2 mb-0" : "px-4 py-2 mb-0"
              } m-auto text-center`}
            >
              <Link
                to="/"
                className="d-flex align-items-center justify-content-center"
              >
                {props?.collapsed ? (
                  // <Icon
                  //   component={() => <AgentLogoIcon width="50" height="40" />}
                  // />
                  <Image
                    preview={false}
                    width={30}
                    src={`${
                      location?.protocol + "//" + location?.host
                    }/mobile-logo.png`}
                  />
                ) : (
                  <Image
                    preview={false}
                    width={"100%"}
                    src={`${
                      location?.protocol + "//" + location?.host
                    }/full-logo.png`}
                  />
                )}
              </Link>
            </h3>
          </div>
          <div className="adminSideBar">
            <Menu
              theme="light"
              defaultSelectedKeys={["/" + path]}
              selectedKeys={["/" + path]}
              mode="inline"
              items={props?.items}
              style={{
                height: "calc(100vh - 117px)",
                marginBottom: "0px",
                overflow: "auto",
              }}
              onClick={(item) => navigate(item?.key)}
            />
          </div>
        </div>
      </div>
    </Sider>
  );
};
export default SideBarBox;
