import { FaPaperPlane } from "react-icons/fa";
import { FaGem } from "react-icons/fa";
import { FaRocket } from "react-icons/fa";
import PlansService from "../service/plan.service";
import { useEffect, useState } from "react";
import { IPlans, PlanFeature } from "@/@types/common";
import AddRegisterForm from "./AddRegisterForm";

const Register = () => {
  const [plansData, setPlansData] = useState<IPlans[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [planId, setPlanId] = useState<number | string | null>();

  const registerModelById = (id: number | string) => {
    setPlanId(id);
    setModalOpen(true);
  };
  const planData = async () => {
    try {
      const { data, status } = await PlansService.all();
      if (status === 200) {
        setPlansData(data);
      }
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    planData();
  }, []);

  return (
    <section className="pricing-section">
      <div className="container">
        <div className="sec-title text-center">
          <span className="title">Get plan</span>
          <h2>Choose a Plan</h2>
        </div>

        <div className="outer-box">
          <div className="row">
            {plansData.map((plan: IPlans, index: number) => (
              <div
                key={index}
                className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay={
                  index === 1 ? "400ms" : index === 2 ? "800ms" : ""
                }
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <div className="icon-outer">
                      <div className="iconsMy">
                        {index === 1 ? (
                          <FaGem />
                        ) : index === 2 ? (
                          <FaRocket />
                        ) : (
                          <FaPaperPlane />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="price-box">
                    <div className="title"> {plan?.name}</div>
                    <h4 className="price">
                      {plan?.currencySymbol}
                      {plan?.price}/
                      <small className="small-Text">{plan?.billingCycle}</small>
                    </h4>
                  </div>
                  <ul className="features">
                    {plan.planFeatures.length > 0 &&
                      plan.planFeatures.map(
                        (feature: PlanFeature, index: number) => {
                          return (
                            <li key={index} className="true">
                              {feature.name}
                            </li>
                          );
                        }
                      )}
                  </ul>
                  <div
                    className="btn-box"
                    onClick={() => registerModelById(plan?.id)}
                  >
                    <span className="theme-btn">BUY plan</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AddRegisterForm
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        setPlanId={setPlanId}
        planId={planId}
      />
    </section>
  );
};
export default Register;
