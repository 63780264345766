import { ISubscriptionPayload } from "@/@types/common";
import API from "@/plugins/api";
/**
 * Adds two numbers together.
 */
class MarketWatchService {
  // eslint-disable-next-line require-jsdoc
  create(payload: ISubscriptionPayload) {
    return API.post(`/market-watch`, payload);
  }

  // eslint-disable-next-line require-jsdoc
  all() {
    return API.get(`/market-watch`);
  }

  // eslint-disable-next-line require-jsdoc
  deleteMarketWatch(identifier: string) {
    return API.delete(`/market-watch/${identifier}`);
  }

}
export default new MarketWatchService();
