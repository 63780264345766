import { Middleware } from "redux";
import { io, Socket } from "socket.io-client";
import { socketActions } from "./reducers/socketReducer";
import { IMarketWatchListData } from "@/@types/common";
import { socketRealTimeUpdates } from "./reducers/marketWatch.reducer";

const socket: Socket = io({
  withCredentials: true,
  rejectUnauthorized: false,
  autoConnect: false,
});
const socketClientMiddleware: Middleware = (store) => {
  return (next) => (action) => {
    const isConnectionEstablished = store.getState().socket.isConnected;

    if (socketActions.startConnecting.match(action)) {
      // if (socket == undefined) {
      //   socket =
      // } else {
      //   next(action);
      //   return;
      // }

      if (
        isConnectionEstablished === false &&
        store.getState().socket.isEstablishingConnection === false
      ) {
        socket.on("connect", () => {
          // store.dispatch(socketActions.connectionEstablished());
          console.log("Socket connected : ", socket.id);
        });
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
        });

        socket.on("disconnect", () => {
          // store.dispatch(socketActions.connectionEstablished());
          console.log("Socket Disconnected : ", socket.id);
        });
        socket.on("connect_error", () => {
          console.log("Socket Disconnected : ", socket.id);
        });
        socket.on("pong", () => {
          console.log("pong", socket.id);
        });
        socket.connect();
        socket.emit("ping");
      }

      // if (store.getState().auth.userData !== null) {
      if (!socket.hasListeners("realtime-updates")) {
        socket.on(`realtime-updates`, (arg: IMarketWatchListData) => {
          store.dispatch(socketRealTimeUpdates(arg))
        });
      }
    } else if (socketActions.disconnect.match(action)) {
      socket.disconnect();
      socket.removeAllListeners();
    }
    next(action);
  };
};

export default socketClientMiddleware;
