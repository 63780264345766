import { Spin, Table } from "antd";

const DataTable = (props: any) => {
  // rowSelection objects indicates the need for row selection
  const selectData = Number(props?.selectedRowKeys);
  const rowSelection = {
    selectData,
    onChange: props?.onSelectChange,
  };

  return (
    <Spin spinning={props?.loading} delay={0} tip="Loading..." size="large">
      <Table
        className={`table-responsive ${props?.className}`}
        columns={props?.columns}
        size={props.size ?? "middle"}
        rowKey={(record: { [x: string]: string }) => record[props.uniqueKey]}
        dataSource={props?.dataSource}
        rowSelection={props?.selected && rowSelection}
        scroll={props?.scroll}
        // scroll={{ x: 1000, y: "calc(100vh - 240px)" }}
        pagination={props?.pagination ? props?.pagination : false}
      />
    </Spin>
  );
};
export { DataTable };
