import { Route, Routes, useLocation, Link } from "react-router-dom";
import { Breadcrumb, Layout, theme } from "antd";
import Navbar from "@/components/HeaderTop";
import SideBarBox from "@/components/SideBarBox";
import { routesData } from "@/common";
import BreadcrumbBox from "@/components/Breadcrumb";
import { useState } from "react";
import { userSelector } from "@/store/reducers/user.reducer";
import { useAppSelector } from "@/hooks";

const { Content } = Layout;
const AdminRoutes = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const { currentUser } = useAppSelector(userSelector);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const location = useLocation();
  const pathSnippets = location?.pathname?.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets?.map((_, index) => {
    const url = `/${pathSnippets?.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link
          className={`text-decoration-none ${
            location?.pathname === url && "active"
          }`}
          to={url}
        >
          {routesData?.find((route) => route?.key === url)?.breadcrumbname}
        </Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link className="text-decoration-none" to="/">
        Home
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  console.log("currentUser-->", currentUser);
  // console.log(routesData?.filter((route: any) => route?.allowedroles?.includes(currentUser?.role?.name)))

  return (
    <Layout className="h-full gap-1">
      {location?.pathname !== "/live-queues" && (
        <SideBarBox
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          items={routesData?.filter((route: any) =>
            route?.allowedroles?.includes(currentUser?.role?.name)
          )}
          baColor={colorBgContainer}
          // isBoxVisible={isBoxVisible}
          // mode="inline"
          // defaultSelectedKeys={["1"]}
          // defaultOpenKeys={["sub1"]}
          // selectedKeys={[location.pathname]}
          // openKeys={[location.pathname]}
          // items={routesData.filter((route: any) =>
          //   route.allowedroles?.includes(currentUser?.roles[0].name)
          // )}
          // baColor={colorBgContainer}
        />
      )}
      <Layout>
        {location?.pathname !== "/live-queues" && (
          <Navbar
            theme="light"
            mode="horizontal"
            isBoxVisible={isBoxVisible}
            setIsBoxVisible={setIsBoxVisible}
          />
        )}
        <Layout
          className={` ${
            location?.pathname !== "/live-queues" ? "customPadding" : "p-0"
          }`}
        >
          {location?.pathname !== "/live-queues" && (
            <BreadcrumbBox
              className="pb-4 my-ant-breadcrumb d-none"
              breadcrumbItems={breadcrumbItems}
            />
          )}
          <Content
            className={`${
              location?.pathname !== "/live-queues"
                ? "overflow-scroll customPadding-20"
                : "p-0"
            }`}
            style={{
              // paddingBottom: 0,
              margin: 0,
              minHeight: 280,
              // background: colorBgContainer,
            }}
          >
            <Routes>
              {routesData?.map((data) => {
                return (
                  <Route
                    key={data.id}
                    path={data.key}
                    element={data.component}
                  />
                );
              })}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AdminRoutes;
