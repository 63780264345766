import { Card, Form, Space, Tooltip, notification } from "antd";
import { Fragment, useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { DataTable } from "@/components/Table";
import { numbersToFormat } from "@/common";
import { IMarketWatchListData, ISubscriptionPayload } from "@/@types/common";
import PageHeading from "@/components/PageHeading";
import { PrimaryButtons } from "@/components/Button";
import MarketWatchFilter from "@/components/MarketWatchFilter";
import marketWatchService from "@/service/marketWatch.service";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  getAllMarketWatchData,
  marketWatchSelector,
} from "@/store/reducers/marketWatch.reducer";

const MarketWatchListPage = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  // const [marketWatchListData, setMarketWatchListData] = useState<
  //   IMarketWatchListData[]
  // >([]);
  const [createSubLoading, setCreateSubLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const { marketWatchListData, loading } = useAppSelector(marketWatchSelector);

  const createMarketWatchData = async (payload: ISubscriptionPayload) => {
    try {
      const { data, status } = await marketWatchService.create(payload);
      if (status === 200) {
        console.log(data);
      }
    } catch (error: any) {
      notification.open({
        message: "Market Watch Failed",
        description: error.response.data.message,
        type: "error",
      });
    }
  };

  const deleteMarketWatchByIdentifier = async (identifier: string) => {
    try {
      return await marketWatchService.deleteMarketWatch(identifier);
    } catch (error: any) {
      console.log("Error to fetch Customer Detail Report", error);
    }
  };

  useEffect(() => {
    document.title = `${props?.title}`;
    dispatch(getAllMarketWatchData());
  }, []);

  const agentColumns = [
    {
      title: "Exchange",
      dataIndex: "Exchange",
      width: 95,
      ellipsis: true,
      render: (text: string) => <p className="m-0">{text ?? "-"}</p>,
    },
    {
      title: "Symbols",
      dataIndex: "Symbol",
      width: 170,
      ellipsis: true,
      render: (text: string) => <p className="m-0">{text ?? "-"}</p>,
    },
    {
      title: "Bid Rate",
      dataIndex: "BuyPrice",
      width: 100,
      ellipsis: true,
      render: (text: number) => (
        <p
          className={`${
            text! > 0
              ? "text-left m-0 text-success"
              : text! === 0
              ? "m-0"
              : "text-left m-0  text-danger"
          }`}
        >
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Ask Rate",
      dataIndex: "SellPrice",
      width: 100,
      ellipsis: true,
      render: (text: number) => (
        <p
          className={`${
            text! > 0
              ? "text-left m-0 text-success"
              : text! === 0
              ? "m-0"
              : "text-left m-0 text-danger"
          }`}
        >
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "LTP",
      dataIndex: "LastTradePrice",
      width: 100,
      ellipsis: true,
      render: (text: number) => (
        <p
          className={`${
            text! > 0
              ? "text-left m-0 text-success"
              : text! === 0
              ? "m-0"
              : "text-left m-0 text-danger"
          }`}
        >
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Change(%)",
      dataIndex: "PriceChangeWithPercentage",
      width: 150,
      ellipsis: true,
      render: (text: string, item: IMarketWatchListData) => (
        <p
          className={`${
            item?.PriceChangePercentage! > 0
              ? "text-left m-0 text-success"
              : item?.PriceChangePercentage! === 0
              ? "m-0"
              : "text-left m-0 text-danger"
          }`}
        >
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Open",
      dataIndex: "Open",
      width: 100,
      ellipsis: true,
      render: (text: number) => <p className="m-0">{text ?? "-"}</p>,
    },
    {
      title: "High",
      dataIndex: "High",
      width: 100,
      ellipsis: true,
      render: (text: number) => (
        <p
          className={`${
            text! > 0
              ? "text-left m-0 text-success"
              : text! === 0
              ? "m-0"
              : "text-left m-0 text-danger"
          }`}
        >
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Low",
      dataIndex: "Low",
      width: 100,
      ellipsis: true,
      render: (text: number) => (
        <p
          className={`${
            text! > 0
              ? "text-left m-0 text-success"
              : text! === 0
              ? "m-0"
              : "text-left m-0 text-danger"
          }`}
        >
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Close",
      dataIndex: "Close",
      width: 100,
      ellipsis: true,
      render: (text: number) => <p className="m-0">{text ?? "-"}</p>,
    },
    {
      title: "Action",
      dataIndex: "userUuid",
      width: 100,
      fixed: "right",
      render: (_text: string, item: IMarketWatchListData) => (
        <Space
          size="middle"
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <Tooltip title="Delete" color={"red"}>
            <div
              className="iconDivHeightWidth bg-danger rounded-circle cursor-pointer"
              onClick={() => {
                const btn = (
                  <Space>
                    <PrimaryButtons
                      type="primary"
                      size="middle"
                      htmlType="submit"
                      name="Yes"
                      loading={deleteBtnLoading}
                      onClick={() => {
                        setDeleteBtnLoading(true);
                        if (item) {
                          deleteMarketWatchByIdentifier(
                            item.InstrumentIdentifier
                          )
                            .then(() => {
                              setDeleteBtnLoading(false);
                              notification.destroy("alert");
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      }}
                    />
                    <PrimaryButtons
                      type="link"
                      size="middle"
                      htmlType="submit"
                      name="No"
                      loading={deleteBtnLoading}
                      onClick={() => {
                        notification.destroy("alert");
                      }}
                    />
                  </Space>
                );
                notification.open({
                  message: `Alert.`,
                  description: `Are you sure you want to delete ${item.Symbol}?`,
                  btn,
                  placement: "top",
                  type: "warning",
                  key: "alert",
                });
              }}
            >
              <DeleteOutlined className="text-white" />
            </div>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);
    setCreateSubLoading(true);
    createMarketWatchData(values)
      .then((res: any) => {
        form.resetFields();
        setCreateSubLoading(false);
        getAllMarketWatchData();
      })
      .catch((error) => {
        setCreateSubLoading(false);
        console.log(error);
      });
  };

  const onFinishFailed = () => {
    // console.log("Failed:", errorInfo);
  };
  console.log("marketWatchListData--->", marketWatchListData);

  return (
    <Fragment>
      <Card
        className="UsersMainDiv campaignMainDiv"
        title={`${props?.title} (${
          marketWatchListData?.length > 0
            ? numbersToFormat(marketWatchListData?.length)
            : 0
        })`}
        extra={
          <div className="d-flex justify-content-start align-items-center gap-2">
            <PageHeading showButton={false} search={false} />
          </div>
        }
        bordered={true}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            market: null,
          }}
          autoComplete="off"
          layout="vertical"
          className="d-flex justify-content-start px-3 pt-2 gap-2 align-items-center w-50"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <MarketWatchFilter />
          <Form.Item className="mb-0 mt-3 pt-1">
            <PrimaryButtons
              type="primary"
              size="middle"
              htmlType="submit"
              icon={<PlusOutlined />}
              name={"Add"}
              // onClick={() => setSaveEndCall(true)}
              loading={createSubLoading}
            />
          </Form.Item>
        </Form>
        <div className="p-2 campaignTableScreen marketWatchTableMain">
          <DataTable
            className="campaignDataTable marketWatchTable"
            loading={loading}
            size="small"
            columns={agentColumns}
            uniqueKey={"userUuid"}
            dataSource={marketWatchListData ?? []}
            pagination={false}
            scroll={{ y: "calc(100vh - 10px)", x: "1000px" }}
            pageSize={10}
          />
        </div>
      </Card>
    </Fragment>
  );
};
export default MarketWatchListPage;
