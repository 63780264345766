import { useState, Fragment, useEffect } from "react";
import { Dropdown, Image, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { logoutUser, userSelector } from "@/store/reducers/user.reducer";
import { HeaderUserIcon } from "./Icons/CardBox";
import Icon from "@ant-design/icons";
import { socketActions } from "@/store/reducers/socketReducer";

const Navbar = (props: any) => {
  const [dropDown, setDropDown] = useState(false);
  const [blinkColor, setBlinkColor] = useState("");
  const { currentUser } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(socketActions.startConnecting(currentUser.id));
    // eslint-disable-next-line
  }, []);

  setTimeout(() => {
    if (blinkColor === "blue") {
      setBlinkColor("#3ab54a");
    } else {
      setBlinkColor("blue");
    }
  }, 1000);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link className="svgIcon" to={"/admin/profile"}>
          Profile
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          className="svgIcon"
          to={"/"}
          onClick={() => {
            dispatch(logoutUser());
          }}
        >
          {/* <LogoutIcon /> */}
          <span>Logout</span>
        </Link>
      ),
    },
  ];

  return (
    <Fragment>
      <header
        className={`header navbar myAdminNavHeader navbar-expand-sm expand-header bg-white py-2 ${
          props.isBoxVisible ? `menu-open` : "menu-close"
        } `}
      >
        <div className="w-100 ps-3">
          {props.isBoxVisible && (
            <h3 className={`text-primary-header d-flex gap-2 px-3 m-0`}>
              <Link to="/">
                <Image preview={false} width={"100%"} src="logo.svg" />
              </Link>
            </h3>
          )}

          <h3 className="m-0 text-capitalize">
            hi,&nbsp;{currentUser.firstName}!
          </h3>
        </div>

        <div className="d-flex justify-content-end w-100 align-items-center">
          <ul className="navbar-item flex-row">
            <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1 d-flex justify-content-between align-items-center">
              <h6 className="m-0 fw-normal text-center ">
                <p className="m-0 text-capitalize username">
                  {currentUser.firstName} {currentUser.lastName} &nbsp;
                </p>
                <p className="m-0 text-capitalize roles">
                  {currentUser.role.name} &nbsp;
                </p>
              </h6>
              <Dropdown menu={{ items }} placement="bottomRight">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle user"
                  id="userProfileDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={dropDown}
                  onClick={(e) => {
                    e.preventDefault();
                    setDropDown(!dropDown);
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <Icon component={() => <HeaderUserIcon />} />{" "}
                  </div>
                </Link>
              </Dropdown>
              {/* <Dropdown overlay={menu} placement="bottomRight">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle user"
                  id="userProfileDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={dropDown}
                  onClick={(e) => {
                    e.preventDefault();
                    setDropDown(!dropDown);
                  }}
                >
                  {/* <Avatar
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 39, xxl: 50 }}
                    icon={<Image src="profile.png" />}
                  /> *
                  <Icon component={() => <HeaderUserIcon />} />
                </Link>
              </Dropdown> */}
            </li>
          </ul>
        </div>
      </header>
    </Fragment>
  );
};

export default Navbar;
